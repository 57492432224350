<template>
  <div class="package-overview client-content-padding">
    <router-view name="bundle" />
    <div class="package-overview__header">
      <h1>{{ labels.modules_overview }}: {{ title }}</h1>
      <!-- <Dropdown :list="states" /> -->
    </div>

    <div class="package-overview__body">
      <div
        class="slider-wrapper"
        v-for="(packageItem, idx) in packageData"
        :key="idx"
      >
        <div
          class="slider"
          :id="`package-overview__body-slider__${idx}`"
          v-dragscroll="dragSlider"
          @mousedown="hold = true"
          @mouseup="hold = false"
          @mousemove="mousemove"
        >
          <div class="slider-inner">
            <MainCard
              :bg-color="packageItem.color"
              :title="packageItem.title"
              :description="packageItem.excerpt"
              :image="content.image"
              :link="getLinkToModule(packageItem._id)"
            />

            <div v-for="(item, index) in packageItem.children" :key="index">
              <Card
                class="h-100"
                :bg-color="packageItem.color"
                :status="item.free"
                :data="{
                  _id: packageItem._id,
                  link: getLinkToModule(item._id),
                }"
                :title="item.title"
                :description="item.excerpt"
                :bookmarkLink="createBookmarks"
                :link="packageItem._id"
              />
            </div>
          </div>

          <IndicatorsButton
            :color="packageItem.color"
            :action="scrollAction"
            :specific-data="{
              elementId: `package-overview__body-slider__${idx}`,
              step: 100,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainCard from "@/components/Cards/MainCard.vue";
import Card from "@/components/Cards/Card.vue";
import IndicatorsButton from "@/components/Buttons/IndicatorsButton";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Bundle",
  components: {
    MainCard,
    Card,
    IndicatorsButton,
  },
  data() {
    return {
      hold: false,
      moved: false,
      packageData: [],
      title: "",
      description: "",
      content: "",
      windowWidth: window.innerWidth,
      dragSlider: window.innerWidth <= 1200 ? false : true,
    };
  },
  mounted() {
    this.loadModules();
    setTimeout(this.watchWindowWidth, 100);
    window.addEventListener("resize", this.watchWindowWidth);
  },

  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("monetizationBundle", {
      states: "states",
    }),
    sliders() {
      return document.querySelectorAll(".slider-wrapper");
    },
  },
  methods: {
    ...mapActions("notification", ["addNotification"]),
    ...mapActions("account", ["getContentOfMonetizationBundle"]),
    watchWindowWidth() {
      this.windowWidth = window.innerWidth;

      let sliders = document.querySelectorAll(".slider-wrapper");

      sliders.forEach((slider) => {
        if (
          slider.querySelector(".slider-inner").scrollWidth > slider.scrollWidth
        ) {
          slider.querySelector(".indicators").style.display = "block";
        } else {
          slider.querySelector(".indicators").style.display = "none";
        }
      });
    },
    createBookmarks(module) {
      this.$router.push({
        name: "bundle-bookmark-create",
        params: {
          monetization_bundle_id: this.$route.params.monetization_bundle_id,
          module_id: module, //  this.moduleId
        },
      });
    },
    loadModules() {
      this.getContentOfMonetizationBundle(
        this.$route.params.monetization_bundle_id
      )
        .then((res) => {
          if (res.success) {
            console.log("Response for bundle content request is:", res);
            console.log(res.data.title);
            this.title = res.data.monetizationBundle.title;
            this.description = res.data.monetizationBundle.description;
            this.content = res.data.monetizationBundle.content;
            this.packageData = res.data.modules;
          }

          if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
        })
        .catch((err) => console.log(err));
    },
    scrollAction(data) {
      let element = document.getElementById(data.elementId);
      let step = data.step;
      if (data.direction === "left") step = -data.step;
      element.scrollBy({ left: step, behavior: "smooth" });
    },
    mousemove() {
      if (this.hold && !this.moved) this.moved = true;
    },
    getLinkToModule(id) {
      return this.$router.resolve({
        name: "bundle-module",
        params: {
          monetization_bundle_id: this.$route.params.monetization_bundle_id,
          module_id: id,
        },
      }).href;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.moved) {
      this.moved = false;
      next(false);
    } else {
      next();
    }
  },
  watch: {
    windowWidth() {
      if (this.windowWidth > 1200) this.dragSlider = true;
      else if (this.windowWidth <= 1200) this.dragSlider = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.package-overview {
  .login-register-link {
    margin-bottom: 15px;

    @media (min-width: 1200px) {
      margin-bottom: 30px;
    }

    @media (min-width: 1600px) {
      margin-bottom: 50px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;

    @media (min-width: 1200px) {
      margin-bottom: 30px;
    }

    @media (min-width: 1600px) {
      margin-bottom: 50px;
    }

    h1 {
      // font-family: 'Oswald', sans-serif;
      line-height: 1.2;
      font-size: 2.5rem;
      font-weight: 500;
    }
  }

  &__body {
    .slider-wrapper {
      position: relative;
    }

    .slider {
      display: flex;
      overflow: hidden;
      gap: 15px;
      cursor: grab;
      cursor: -webkit-grab;
      cursor: -moz-grab;
      margin-bottom: 15px;
      @media (max-width: 1200px) {
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }

        ::-webkit-scrollbar-thumb {
          display: none;
        }
      }

      @media (min-width: 1200px) {
        margin-bottom: 30px;
        gap: 30px;

        > div {
          flex: 0 0 180px;
          max-width: 180px;
        }
      }

      @media (min-width: 1600px) {
        margin-bottom: 50px;
        gap: 50px;

        > div {
          flex: 0 0 190px;
          max-width: 190px;
        }
      }
      .slider-inner {
        display: flex;
        gap: 15px;
        & > div {
          flex: 0 0 220px;
          max-width: 220px;
        }
      }
    }

    ::v-deep .indicators {
      position: absolute;
      right: 50px;
      bottom: 25px;
    }
  }
}
</style>
