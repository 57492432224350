<template>
  <span class="indicators" :style="styles">
    <button
      class="btn-left"
      @click="action({ direction: 'left', ...specificData })"
    >
      <font-awesome-icon class="icon" :icon="`chevron-left`" />
    </button>
    <button
      class="btn-right"
      @click="action({ direction: 'right', ...specificData })"
    >
      <font-awesome-icon class="icon" :icon="`chevron-right`" />
    </button>
  </span>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronLeft, faChevronRight);
const colors = require("/colors.config.json");
import "bootstrap/dist/css/bootstrap.min.css";
export default {
  name: "Indicators",
  data() {
    return {
      styles: {
        "--color": this.color,
        "--background-color": this.bgColor,
      },
    };
  },
  props: {
    specificData: {
      type: Object,
      default: () => {},
    },
    action: {
      type: Function,
      default: () => {},
    },
    bgColor: {
      type: String,
      default: `${colors.white}`,
    },
    color: {
      type: String,
      default: `${colors.sienna}`,
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin transition-ease {
  transition: all 0.3s ease;
}

.indicators {
  .icon {
    height: 14px;
  }

  .btn-left {
    border-right: none !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    @include transition-ease;
  }

  .btn-right {
    border-left: none !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    @include transition-ease;
  }

  .btn-left,
  .btn-right {
    width: 30px;
    height: 30px;
    color: var(--color);
    background-color: var(--background-color);
    border: 0.5px solid var(--color);
  }

  .btn-left:hover,
  .btn-left:active,
  .btn-right:hover,
  .btn-right:active {
    color: var(--background-color) !important;
    background-color: var(--color);
    outline: none;
  }

  .btn-right:focus,
  .btn-left:focus {
    outline: none;
  }
}
</style>
